import { render, staticRenderFns } from "./Phase3.vue?vue&type=template&id=910d8edc&"
import script from "./Phase3.vue?vue&type=script&lang=js&"
export * from "./Phase3.vue?vue&type=script&lang=js&"
import style0 from "./Phase3.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Phase3.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VDivider,VStepper,VStepperContent,VStepperHeader,VStepperStep,VTab,VTabItem,VTabs,VTabsItems})
