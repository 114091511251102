<template>
  <div class="phase3">
    <vue-gallery
      id="gallery-update-phase3"
      :images="images"
      :index="index"
      @close="index = null"
    ></vue-gallery>
    <div>
      <section>
        <div class="bsat__title">{{ $t("title") }}</div>
        <div class="bsat__paragraph">{{ $t("paragraph") }}</div>
        <read-more>
          <div class="bsat__paragraph">{{ $t("paragraph2") }}</div>
        </read-more>
      </section>

      <!-- ******************************** WHO TO INVOLVE ******************************** -->
      <section>
        <div class="bsat__subtitle">{{ $t("whoToInvolve.title") }}</div>
        <div class="bsat__paragraph">{{ $t("whoToInvolve.paragraph") }}</div>
        <read-more>
          <div class="bsat__paragraph">{{ $t("whoToInvolve.paragraph2") }}</div>
          <div class="bsat__paragraph">{{ $t("whoToInvolve.paragraph3") }}</div>
          <ol class="mb-2">
            <li>
              {{ $t("whoToInvolve.listItem1.title") }}
              <ul>
                <li>{{ $t("whoToInvolve.listItem1.item1") }}</li>
                <li>{{ $t("whoToInvolve.listItem1.item2") }}</li>
              </ul>
            </li>
            <li>{{ $t("whoToInvolve.listItem2") }}</li>
            <li>{{ $t("whoToInvolve.listItem3") }}</li>
          </ol>
          <div class="bsat__paragraph">{{ $t("whoToInvolve.paragraph4") }}</div>
        </read-more>
      </section>

      <!-- ***************** STEPS ****************** -->
      <div class="steps">
        <div class="bsat__subtitle">{{ $t("stepsTitle") }}</div>
        <v-stepper class="elevation-0 bg-off_white" v-model="steps">
          <!-- ************* STEPPER HEADER **************** -->
          <v-stepper-header
            :style="{ maxWidth: $vuetify.breakpoint.smAndUp ? '50%' : '' }"
          >
            <v-stepper-step @click="steps = 1" step="1"> </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step @click="steps = 2" step="2"> </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step @click="steps = 3" step="3"> </v-stepper-step>
          </v-stepper-header>

          <!-- ***************** STEPPER CONTENT ***********************-->
          <v-stepper-content
            v-for="(step, i) in phase3"
            :key="i"
            :step="i + 1"
            class="steps__content px-0"
          >
            <keep-alive>
              <component :is="step.component"></component>
            </keep-alive>
          </v-stepper-content>
        </v-stepper>
      </div>

      <Divider></Divider>

      <div class="bsat__subtitle">{{ $t("power4BioExperience") }}</div>
      <!--************************* TABS **************************** -->
      <div class="phase3__tabs">
        <v-tabs
          v-model="tab"
          class="bsat__tabs"
          background-color="transparent"
          slider-color="dark"
          color="dark"
          show-arrows
          center-active
        >
          <v-tab
            v-for="(item, i) in footerTabs"
            :key="i"
            class="mr-4 mr-sm-8"
            :style="
              $vuetify.breakpoint.xsOnly
                ? 'min-width: 90px'
                : 'min-width: 160px'
            "
          >
            {{ $t(item.tab) }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="py-4">
          <!-- ********** NOTES TAB ******* -->
          <v-tab-item>
            <div class="bg-off_white">
              <div class="bsat__subtitle">{{ $t("notes.notes1.title") }}</div>
              <div class="bsat__paragraph">
                {{ $t("notes.notes1.paragraph") }}
              </div>
              <div class="bsat__subtitle">{{ $t("notes.notes2.title") }}</div>
              <div class="bsat__paragraph">
                {{ $t("notes.notes2.paragraph") }}
              </div>
            </div>
          </v-tab-item>

          <!-- ********** OPINIONS TAB ******* -->
          <!-- <v-tab-item>
            <v-card flat color="off_white">
              <v-card-text class="px-0">
                <v-list dense class="bg-off_white">
                  <v-list-item
                    dense
                    class="text-default"
                    v-for="(item, i) in currentOpinions"
                    :key="i"
                  >
                    <v-list-item-avatar size="40">
                      <v-img src="@/assets/icons/profile.png"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        item.subtitle
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-pagination
                    class="pagination"
                    color="dark"
                    v-model="page"
                    :length="2"
                  ></v-pagination>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item> -->
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "notes": {
        "notes1": {
              "title": "Baviera",
              "paragraph": "(In the region of Bavaria, the bioeconomy strategy is still under development.) Having learned from the questionnaires (available in this Phase 3), we won a clear picture of the current status in Bavaria and identified next steps for sound strategy implementation.  This also led to us being consulted by local policy makers when starting the process of strategy development. We could contribute project knowledge as well as point out key issues and central questions to be addressed in the region. (In return, the timing in Bavaria allowed us to learn live from current experiences, reflect on how to ideally structure a strategy development process and how to involve stakeholders.) Thanks to the POWER4BIO project we provided bioeconomy strategies from partner regions and neighboring regions and thereby offering experience insights to local policy makers."
          },
        "notes2": {
              "title": "Italy",
              "paragraph": "The possibility to know best practices developed in other regions, not only on the technological side but also on the policy and financial instruments, was inspiring and gave more valuable inputs at the discussion."
          },                     
      "tabTitle": "Notes",
      "title": "The definition of Bioeconomy"
    },
    "opinions": {
      "list": [
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 1"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 2"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 3"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 4"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 5"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 6"
        }
      ],
      "title": "Opinions"
    },
    "paragraph": "Bioeconomy strategy designs vary significantly in terms of their administrative complexity. Different bioeconomy strategies require different degrees of oversight and monitoring. The determination of the required capacities has to be based on the needs of different design options. On the other hand, studying the experience of other regions with similar circumstances and speaking to counterparts in those regions can also help to understand how these needs play out in practice. Besides, the regions in the process of updating their existing bioeconomy strategies are encouraged to maximise predictability on the one hand and flexibility on the other. While investors and entrepreneurs need predictable conditions to develop businesses, policymakers typically seek to retain flexibility to react to new information, changing political goals and public priorities",
    "paragraph2": "All those elements are part of Phase 3, as a revision of how the region has evolved in the field of bioeconomy. Since the launch of the existing bioeconomy strategy up to now. At this stage, the region is invited to open a new debate towards the future, in view to the existing tools and lessons learnt up to now. The described Steps of this Phase 3 will stimulate the critical review of the existing bioeconomy strategy of your region in the identification of new opportunities, targets, value chains, markets, etc. Phase 4 will also imply some revision activities to complement and finish Phase 3 analysis. ",
    "power4BioExperience": "The POWER4BIO experience",
    "realExamples": {
      "examples": [
        {
          "icon": "ANDALUSIA.jpg",
          "title": "Goals and priority Areas in Andalusia (Spain)"
        },
        {
          "icon": "BAVARIA.jpg",
          "title": "Goals and priority Areas in Bavaria (Germany)"
        },
        {
          "icon": "CENTRAL_GERMANY.jpg",
          "title": "Goals and priority Areas in Central Germany (Germany)"
        },
        {
          "icon": "FLANDERS.jpg",
          "title": "Goals and priority Areas in Flanders (Belgium)"
        },
        {
          "icon": "ITALY.jpg",
          "title": "Goals and priority Areas in Italian regions (Italy)"
        }
      ],
      "cardTitle": "Example of a SWOT analysis",
      "paragraph": "To be added by the end of March 2021",
      "tabTitle": "Real Examples"
    },
    "stepsTitle": "Overview of the steps",
    "subtitle": "Who to involve in this phase?",
    "title": "Phase 3: Revision of the existing Bioeconomy strategy",
    "whoToInvolve": {
      "title": "Who to involve in this phase?",
      "paragraph": "As mentioned before, it is very likely that your region accounts already for an structure and constituted bioeconomy group, which would be the equivalent of the Regional Bioeconomy Hub. In any case, it is recommended that in this Phase 3 an analysis of existing stakeholders and potential new members of this RBH is undertaken. It would align the constitution of the RBH to the newly agreed priorities for your region in this process of updating the bioeconomy strategy. The following recommendations are applicable to regions without a bioeconomy strategy. Nevertheless, some aspects would be worth reviewing in your region too, in the search of a broader engagement of stakeholders, adapted to the new priorities.",
      "paragraph2": "The steps here described assume that a decision at regional policy level to go ahead with the strategy development has been achieved. Possibly through the leadership of the RBH and (regional) policy makers involved in it. A regional Interministerial or interdepartmental agreement can be an optional method to signalize the agreement and interest to develop the regional bioeconomy strategy. This mandate implies their ability to represent and communicate the decision to the national level and general public through usual dissemination channels on behalf of the regional bioeconomy community.",
      "paragraph3": "Here it is important to differentiate between the team that will be coordinating and working directly on the strategy development process (hereafter called strategy development working group) and other involved stakeholders through the participatory process to hold into account their considerations, perceptions, interest and concerns during specific phases of the process. In whichever case, the group in charge of the bioeconomy strategy definition should be a recognised and skill group of experts and representatives from the main decision-making bodies with responsibilities in the bioeconomy field.",
      "listItem1": {
        "title": "Representatives from the formed Interministerial/interdepartmental group, which has previously signalized their agreement to work together towards the strategy development. Within this group is common to have two subgroups:",
        "item1": "The decision-level: policy-makers within involved ministries or regional departments. The ones appointed to this group have as role to take the main decisions and approve several aspects within the process. For instance, approve the designed participatory approach with regional stakeholders or revise and approve the draft of the strategy.",
        "item2": "The technical-level: technical experts within ministries or regional departments, that will validate collected knowledge during participatory process and will draft the document of the strategy. This group will have constant communication with the decision-level for feedback and advice."
      },
      "listItem2": "Advisory group: formed by experts of diverse areas, called by the Interministerial/interdepartmental group to enrich the process. Ideally the RBH should already have involved these experts.",
      "listItem3": "Managers for public consultation processes and communication chanels: group or institution in charge of designing and carrying out the participatory process with the supervision of the Interministerial/interdepartmental group. The RBH will be fundamental in this step, since it represents at this point a platform to reach several stakeholders of the regional bioeconomy community.",
      "paragraph4": "Other stakeholders to be engaged within the participatory process of the strategy development are mostly those involved in the RBH. However, in case there is any missing key stakeholder not participating in the RBH before, would be identified, and hopefully engaged, within participatory process activities. The final composition of the involved participants should be flexible and tailor-made to the needs of each region. Lastly, it is also recommended to maintain certain room for manoeuvre in engaging with additional participants on a demand driven topic of discussion."
   }
  }
}
</i18n>

<script>
import VueGallery from "vue-gallery";
import { EventBus } from "@/utils/EventBus";
import { getArrayPiece } from "@/utils/helpers";
import Divider from "@/components/Divider";
import ReadMore from "../../ReadMore.vue";
export default {
  name: "UpdatePhase3",
  components: {
    Step1: () => import("./Step1"),
    Step2: () => import("./Step2"),
    Step3: () => import("./Step3"),
    Divider,
    ReadMore,
    VueGallery
  },
  data() {
    return {
      page: 1,
      steps: 1,
      tab: 0,
      step2Tab: 0,
      faoLink:
        "http://www.fao.org/climate-change/our-work/areas-of-work/bioeconomy/en/",
      scarLink: "https://www.scar-swg-sbgb.eu/the-bioeconomy",
      euCommisionLink: "https://ec.europa.eu/research/bioeconomy/index.cfm",
      footerTabs: [
        // { tab: "realExamples.tabTitle" },
        { tab: "notes.tabTitle" }
        // { tab: "opinions.title" }
      ],
      phase3: [
        { component: "Step1" },
        { component: "Step2" },
        { component: "Step3" }
      ],
      realExamplesFiles: [
        // TODO: 1.3-1
        "",
        // TODO: 1.3-2
        "",
        // TODO: 1.3-3
        "",
        // TODO: 1.3-4
        "",
        // TODO: 1.3-5
        ""
      ],
      index: 0,
      images: [
        require("@/assets/icons/regions/ANDALUSIA.jpg"),
        require("@/assets/icons/regions/BAVARIA.jpg"),
        require("@/assets/icons/regions/CENTRAL_GERMANY.jpg"),
        require("@/assets/icons/regions/FLANDERS.jpg"),
        require("@/assets/icons/regions/ITALY.jpg")
      ]
    };
  },
  computed: {
    orderedList() {
      return Object.values(this.$t("orderedList"));
    },
    opinions() {
      return Object.values(this.$t("opinions.list"));
    },
    examples() {
      return Object.values(this.$t("realExamples.examples"));
    },
    currentOpinions() {
      return getArrayPiece(this.opinions, this.page, 3);
    }
    // step2PolicyAnalysisMaterial() {
    //   return this.$t("step1.policyAnalysis.Materialparagraph").split(":");
    // },
  },
  watch: {
    steps(val) {
      if (window) {
        const url = new URL(window.location);
        url.searchParams.set("step", val);
        window.history.pushState({}, "", url);
      }
    }
  },
  mounted() {
    const query = this.$route.query;
    if (query.step) {
      this.steps = Number(query.step);
    }

    EventBus.$on("goToStep", stepNumber => {
      this.steps = stepNumber;
    });
  },
  methods: {
    getArrayFromObject(arr) {
      return Object.values(arr);
    },
    goToTab(tab) {
      EventBus.$emit("goToTab", tab);
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/main";

.phase3 {
  margin-top: 50px;

  .v-window {
    background-color: $off_white;
  }

  .pagination {
    .v-pagination__item {
      background: $inactive_grey;
      min-width: 18px !important;
      height: 20px !important;
      font-size: 10px;
      margin: 0px 3px;
      box-shadow: none !important;
    }
    .v-pagination__navigation {
      background: $inactive_grey;
      min-width: 18px !important;
      width: 18px !important;
      height: 20px !important;
      font-size: 10px;
      box-shadow: none !important;
    }
    .v-pagination__item .v-pagination__item--active {
      box-shadow: none;
    }
    .mdi-chevron-left {
      font-size: 12px;
    }
    .mdi-chevron-right {
      font-size: 12px;
    }
  }

  &__tabs {
    margin-top: 40px;
    margin-bottom: 200px;

    .bsat__paragraph {
      font-size: 14px;
      letter-spacing: 0;
    }
  }

  .v-tab {
    font-family: $font-family;
    text-transform: none;
    letter-spacing: normal;
    justify-items: start;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }

  .steps {
    &__title {
      font-family: $font-family;
      color: $light_green;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.6;
      letter-spacing: 0.2px;
      text-align: left;
    }

    &__content {
      background-color: #ffffff;
      border-radius: 8px;
      border: 1px solid $light_green;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }
    .v-stepper__header {
      background-color: $off_white;
      box-shadow: none;
    }

    .v-stepper__step--active {
      .v-stepper__step__step {
        align-items: center;
        border-radius: 50%;
        display: inline-flex;
        font-size: 16px;
        font-weight: bold;
        justify-content: center;
        height: 36px;
        min-width: 36px;
        width: 36px;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        background-color: $off_white !important;
        border: 4px solid $light_green !important;
        border-radius: 50%;
        color: $light_green !important;
      }
    }

    .v-stepper__step--inactive {
      .v-stepper__step__step {
        align-items: center;
        border-radius: 50%;
        display: inline-flex;
        font-size: 16px;
        font-weight: bold;
        justify-content: center;
        height: 36px;
        min-width: 36px;
        width: 36px;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        background-color: $off_white !important;
        border: 4px solid $inactive_grey !important;
        border-radius: 50%;
        color: $inactive_grey !important;
      }
    }

    .v-stepper__step__step {
      align-items: center;
      border-radius: 50%;
      display: inline-flex;
      font-size: 16px;
      font-weight: bold;
      justify-content: center;
      height: 36px;
      min-width: 36px;
      width: 36px;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      background-color: $off_white !important;
      border: 4px solid $inactive_grey !important;
      border-radius: 50%;
      color: $inactive_grey !important;
    }
    .v-stepper__step__step:hover {
      cursor: pointer;
    }
  }
}
</style>
